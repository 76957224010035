import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/attributes',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'attributes',
          component: () => import('../views/attributes/AttributesIndex.vue')
        },
        {
          path: 'add',
          name: 'attributesAdd',
          component: () => import('../views/attributes/AttributesAdd.vue')
        },
        {
          path: 'update/:attributeID',
          name: 'attributesUpdate',
          component: () => import('../views/attributes/AttributesUpdate.vue')
        },
        {
          path: 'update/:attributeID/:valueID',
          name: 'valuesUpdate',
          component: () => import('../views/attributes/ValuesUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
