import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { tryAutoLogin, checkAuthenticated } from '../middleware/Auth'
import { redirect } from '../middleware/Redirect'

import HomeRoutes from './HomeRoutes'

import ScenariosRoutes from './ScenariosRoutes'

import UsersRoutes from './UsersRoutes'
import AddressesRoutes from './AddressesRoutes'
import EmailsRoutes from './EmailsRoutes'
import KidsRoutes from './KidsRoutes'
import NanniesRoutes from './NanniesRoutes'
import ParentsRoutes from './ParentsRoutes'
import HashtagsRoutes from './HashtagsRoutes'
import ReferencesRoutes from './ReferencesRoutes'

import AnnouncementsRoutes from './AnnouncementsRoutes'
import AttributesRoutes from './AttributesRoutes'
import ReviewsRoutes from './ReviewsRoutes'
import InvitesRoutes from './InvitesRoutes'
import ChatsRoutes from './ChatsRoutes'
import DealsRoutes from './DealsRoutes'

import PaymentsRoutes from './PaymentsRoutes'
import ProductsRoutes from './ProductsRoutes'

import BlogRoutes from './BlogRoutes'
import WritersRoutes from './WritersRoutes'
import CategoriesRoutes from './CategoriesRoutes'
import TagsRoutes from './TagsRoutes'
import CoversRoutes from './CoversRoutes'
import CommentsRoutes from './CommentsRoutes'

import PagesRoutes from './PagesRoutes'
import StatsRoutes from './StatsRoutes'
import ExcelRoutes from './ExcelRoutes'
import AdminsRoutes from './AdminsRoutes'
import AdminRoutes from './AdminRoutes'

import AuthRoutes from './AuthRoutes'
import LogoutRoutes from './LogoutRoutes'
import LayoutDefault from '../layouts/LayoutDefault.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LayoutDefault,
    children: [
      HomeRoutes,
      ScenariosRoutes,
      UsersRoutes,
      AddressesRoutes,
      EmailsRoutes,
      KidsRoutes,
      NanniesRoutes,
      ParentsRoutes,
      AnnouncementsRoutes,
      AttributesRoutes,
      ReviewsRoutes,
      InvitesRoutes,
      ChatsRoutes, 
      DealsRoutes,
      PaymentsRoutes,
      ProductsRoutes,
      BlogRoutes,
      WritersRoutes,
      CategoriesRoutes,
      TagsRoutes,
      CoversRoutes,
      CommentsRoutes,
      PagesRoutes,
      StatsRoutes,
      ExcelRoutes,
      AdminsRoutes,
      AdminRoutes,
      HashtagsRoutes,
      ReferencesRoutes
    ]
  },
  AuthRoutes,
  LogoutRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

router.beforeEach(tryAutoLogin)
router.beforeEach(checkAuthenticated)
router.beforeEach((to) => redirect(to))

export default router
