export default {
  comment_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie Komentarze'
    },
    published: {
      icon: 'universalAccessSolid',
      text: 'Opublikowane'
    },
    unpublished: {
      icon: 'coinsSolid',
      text: 'Nie opublikowane'
    }
  },
  list: {
    commentStatus: 'Status komentarza',
    authorName: 'Autor Komentarza'
  },
  toasts: {
    commentsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy komentarzy.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    updatedComment: {
      title: 'Komentarz został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedCommentError: {
      title: 'Coś poszło nie tak!',
      msg: 'Komentarz nie zostały zmieniony.'
    },
    deletedComments: {
      title: 'Komentarze zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCommentsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Komentarze nie zostały usunięte.'
    },
    deletedComment: {
      title: 'Komentarz został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCommentError: {
      title: 'Coś poszło nie tak!',
      msg: 'Komentarz nie został usunięty.'
    },
    changeIsActive: {
      title: 'Zmiany dla komentarzy zostały zapisane!',
      msg: 'Dane zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status komentarzy nie został zmieniony.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Informacje o komentarzu nie zostały pobrane.'
    }
  }  
}
