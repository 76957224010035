export default {
  deal_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    confirmed: {
      icon: 'checkCircleSolid',
      text: 'Potwierdzone współprace'
    },
    inconfirmed: {
      icon: 'userTimesSolid',
      text: 'Nie potwierdzone współprace'
    },
    reject: {
      icon: 'exclamationCircleSolid',
      text: 'Odrzucone'
    }
  },
  list: {
    name: 'Imię i nazwisko'
    // type: 'Typ'
  },
  toasts: {
    dealsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy współprac.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    deletedDeals: {
      title: 'Współprace zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedDealsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Współprace nie zostały usunięte.'
    },
    deletedDeal: {
      title: 'Współpraca została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedDealError: {
      title: 'Coś poszło nie tak!',
      msg: 'Współpraca nie została usunięta.'
    }
  }
}
  