const Config = {
  panel: {
    baseUrl: import.meta.env.VITE_API_SERVER,
    internal: true
  },
  scenarios: {
    // baseUrl: import.meta.env.VITE_API_SCENARIOS,
    baseUrl: 'https://znscenario.x25.pl',
    internal: false
  }
}

export default Config