<template>
  <div class="flex h-screen overflow-x-hidden">
    <div class="flex flex-col flex-1 relative overflow-x-hidden">
      <panel-navbar />
      <div class="h-full overflow-auto">
        <router-view />
      </div>
      <transition
        v-bind="opacity.opacityTransitionClasses"
      >
        <div
          v-if="useSidenav.isOpen && useSidenav.isMobile"
          class="absolute inset-0"
          @click="useSidenav.close"
        >
          <div class="absolute inset-0 bg-white opacity-80" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useSidenavStore } from '@stores/sidenav'
import useOpacityQuark from '@quarks/OpacityQuark'
import PanelNavbar from '@panel/Navbar'
export default defineComponent({
  components: {
    PanelNavbar
  },
  setup () {
    const opacity = useOpacityQuark()
    const useSidenav = useSidenavStore()
    const isOpen = computed(() => useSidenav.isOpen)
    return {
      useSidenav,
      opacity,
      isOpen
    }
  }
})
</script>
