export default {
  email_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    active: {
      icon: 'universalAccessSolid',
      text: 'Subskrypcja aktywna'
    },
    inactive: {
      icon: 'coinsSolid',
      text: 'Brak Subskrypcji'
    }
  },
  list: {
    name: 'Email',
    // type: 'Typ',
    user: 'Imię i Nazwisko'
  },
  toasts: {
    refreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy maili.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    emailsChanged: {
      title: 'Adres email został zmieniony!'
    },
    emailsChangedError: {
      title: 'Coś poszło nie tak!',
      msg: 'Adres email nie został zmieniony.'
    },
    createdEmail: {
      title: 'Email został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    createdEmailError: {
      title: 'Coś poszło nie tak!',
      msg: 'Email nie został dodany.'
    },
    updatedEmail: {
      title: 'Email został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedEmailError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zawsze musi istnieć 1 adres główny. Dodaj inny adres, żeby móc zmienić status tego'
    }
  }  
}
  