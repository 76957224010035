import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/announcements',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'announcements',
          component: () => import('../views/announcements/AnnouncementsIndex.vue')
        },
        {
          path: 'add/:parentID',
          name: 'announcementAdd',
          component: () => import('../views/announcements/AnnouncementsAdd.vue')
        },
        {
          path: 'update/:announcementID',
          name: 'announcementsUpdate',
          component: () => import('../views/announcements/AnnouncementsUpdate.vue')
        },
        {
          path: 'update/:announcementID/:attributeID',
          name: 'announcementsUpdateAttr',
          component: () => import('../views/attributes/ValuesChange.vue')
        }
      ]
    }
  ]
}

export default routes
