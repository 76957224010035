const routes = {
  path: '/auth',
  component: () => import('../layouts/LayoutAuth.vue'),
  children: [
    {
      path: '',
      name: 'authLogin',
      component: () => import('../views/auth/AuthLogin.vue')
    },
    {
      path: 'login',
      redirect: '/auth'
    },
    {
      path: 'recover',
      name: 'authRecover',
      component: () => import('../views/auth/AuthRecover.vue')
    },
    {
      path: 'new-password/:recoverkey/:userid',
      name: 'authNewpass',
      component: () => import('../views/auth/AuthNewPassword.vue')
    }
  ]
}

export default routes