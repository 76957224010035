export default {
  reference_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie'
    },
    accepted: {
      icon: 'checkCircleSolid',
      text: 'Zaakceptowane'
    },
    unaccepted: {
      icon: 'timesCircleSolid',
      text: 'Niezaakceptowane'
    }
  },
  toasts: {
    updatedReferenceSuccess: {
      title: 'Referencja została zaktualizowana!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedReferenceError: {
      title: 'Coś poszło nie tak!',
      msg: 'Referencja nie została zaktualizowana.'
    },
    createdReferenceSuccess: {
      title: 'Referencja została dodana!',
      msg: 'Dane zostały zapisane.'
    },
    createdReferenceError: {
      title: 'Coś poszło nie tak!',
      msg: 'Referencja nie została dodana.'
    },
    deletedReferencesSuccess: {
      title: 'Referencje zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedReferencesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Referencje nie zostały usunięte.'
    },
    deletedReferenceSuccess: {
      title: 'Referencja została usunięta.',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedReferenceError: {
      title: 'Coś poszło nie tak!',
      msg: 'Referencja nie została usunięta.'
    },
    refreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy referencji.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    }
  }
}
