export default {
  announcement_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    active: {
      icon: 'checkCircleSolid',
      text: 'Aktywne'
    },
    inactive: {
      icon: 'exclamationCircleSolid',
      text: 'Nieaktywne'
    }
  },
  list: {
    AnnouncementTitle: 'Tytuł ogłoszenia'
  },
  toasts: {
    announcementsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy ogłoszeń.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    updatedAnnouncement: {
      title: 'Ogłoszenie zostało zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedAnnouncementError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ogłoszenie nie zostało zmienione.'
    },
    deletedAnnouncements: {
      title: 'Ogłoszenia zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAnnouncementsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ogłoszenia nie zostały usunięte'
    },
    deletedAnnouncement: {
      title: 'Ogłoszenie zostało usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAnnouncementError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ogłoszenie nie zostało usunięte.'
    },
    changeIsActive: {
      title: 'Ogłoszenia zostały zmienione!',
      msg: 'Zmiany dla ogłoszeń zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zmiany dla ogłoszeń nie zostały zapisane.'
    },
    addedToastError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać informacji o ogłoszeniu.'
    },
    createdAnnouncement: {
      title: 'Ogłoszenie zostało dodane!',
      msg: 'Dane zostały zapisane.'
    },
    createdAnnouncementError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ogłoszenie nie zostało dodane.'
    }
  }
}
  