export default {
  kid_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    male: {
      icon: 'marsSolid',
      text: 'Chłopiec'
    },
    female: {
      icon: 'venusSolid',
      text: 'Dziewczynka'
    }
  },
  list: {
    name: 'Imię Dziecka',
    gender: 'Płeć',
    parent: 'Rodzic'
  },
  toasts: {
    refreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy dzieci.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    addedKid: {
      title: 'Dziecko zostało dodane!',
      msg: 'Zmiany zostały zapisane.'
    },
    addedKidError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dziecko nie zostało dodane.'
    },
    updatedKid: {
      title: 'Dane dziecka zostały zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedKidError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane dziecka nie zostały zmienione.'
    },
    deletedKids: {
      title: 'Profile dzieci zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedKidsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Profile dzieci nie zostały usunięte.'
    },
    deletedKid: {
      title: 'Profil dziecka został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedKidError: {
      title: 'Coś poszło nie tak!',
      msg: 'Profil dziecka nie został usunięty.'
    }
  }
}
