export default {
  fields: {
    userName: 'Nazwa użytkownika',
    userPassword: 'Hasło',
    userPasswordRepeat: 'Powtórz hasło',
    userPasswordOld: 'Aktualne hasło',
    userEmail: 'Email'
  },
  buttons: {
    login: 'Zaloguj się',
    recover: 'Przypomnij hasło',
    newpassword: 'Ustaw hasło'
  },
  validations: {
    userName: {
      info: 'To będzie twój login. Dozwolone są tylko litery i cyfry, nie możesz użyć polskich znaków.',
      required: 'Podaj nazwę użytkownika',
      minLength: 'Nazwa użytkownika powinna składać się z co najmniej {minLength} znaków.',
      maxLength: 'Nazwa użytkownika może składać się z najwyżej {maxLength} znaków.',
      regex: 'Nazwa użytkownika może składać się z liter, cyfr.',
      reserved: 'Wybrana nazwa użytkownika jest już zarezerwowana.'
    },
    userPassword: {
      required: 'Podaj hasło',
      minLength: 'Hasło powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Hasło może składać się z najwyżej {maxLength} znaków.',
      sameAs: 'Powtórz prawidłowo podane hasło.'
    },
    userPasswordRepeat: {
      required: 'Powtórzenie hasła jest wymagane',
      sameAs: 'Powtórz prawidłowo podane hasło.'
    },
    userPasswordOld: {
      info: 'To jest hasło, z którego aktualnie korzystasz.',
      required: 'Podaj hasło',
      minLength: 'Hasło powinno składać się z co najmniej {minLength} znaków.',
      maxLength: 'Hasło może składać się z najwyżej {maxLength} znaków.'
    },
    userEmail: {
      info: 'Podaj adres na który wyślę mail z aktywacją konta.',
      inforecover: 'Wpisz adres email podany podczas rejestracji konta na który wyślę mail z kluczem aktywacyjnym nowego hasła.',
      email: 'To nie jest poprawny email.',
      reserved: 'Podany adres email jest już wykorzystywany przez inne konto.',
      required: 'Podaj adres email.'
    }
  },
  alerts: {
    recoverSuccess: 'Link do aktywacji nowego hasła został wysłany na Twój adres email.',
    wrongData: 'Wystąpił błąd odczytu wysłanych danych. Spróbuj jeszcze raz.',
    wrongPassword: 'Podaj poprawne hasło i nazwę użytkownika.',
    wrongUserName: 'Taki użytkownik nie istnieje.',
    wrongEmail: 'Taki adres email nie istnieje.',
    wrongStatus: 'Konto jest nieaktywne lub zostało zablokowane.',
    newpassSuccess: 'Hasło zostało zmienione! Możesz się teraz zalogować.',
    newpassExpired: 'Link aktywacyjny dla zmiany hasła wygasł.',
    wrongNewpass: 'Zmiana hasła nie powiodła się.',
    wrongOldpass: 'Podane aktualne hasło jest nieprawidłowe.',
    changeData: 'Dane zostały zmienione.'
  },
  login: {
    title: 'Logowanie',
    info: {
      pre: 'Zaloguj się za pomocą nazwy użytkownika i hasła.',
      url: '/#/auth/register',
      action: 'Zarejestruj się'
    },
    recover: {
      title: 'Nie pamiętasz swojego hasła?',
      url: '/#/auth/recover'
    }
  },
  recover: {
    title: 'Przypomnij hasło',
    info: {
      pre: 'Wpisz email, którego użyłeś(aś) do rejestracji konta. Otrzymasz link, umożliwiający ustawienie nowego hasła. Kliknąłeś już w otrzymany link?',
      url: '/#/auth',
      action: 'Zaloguj się'
    }
  },
  newpassword: {
    title: 'Ustaw nowe hasło',
    info: {
      pre: 'Zmień teraz hasło, aby odzyskać dostęp do konta, a następnie',
      url: '/#/auth',
      action: 'zaloguj się'
    },
    recover: {
      msg: 'Prawdopodbnie upłyneło zbyt dużo czas od chwili gdy otrzymałeś link aktywacyjny, lub już został on już wykorzystany. Możesz ',
      title: 'wysłać nowy link aktywacyjny',
      url: '/#/auth/recover'
    }
  }
}
