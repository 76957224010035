import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/nannies',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'nannies',
          component: () => import('../views/nannies/NanniesIndex.vue')
        },
        {
          path: 'update/:nannyID',
          name: 'updateNanny',
          component: () => import('../views/nannies/NanniesUpdate.vue')
        },
        {
          path: 'update/:nannyID/:attributeID',
          name: 'updateNannyAttr',
          component: () => import('../views/attributes/ValuesChange.vue')
        },
        {
          path: 'update/:nannyID/reference/:referenceID',
          name: 'updateNannyReference',
          component: () => import('../views/references/ReferencesUpdate.vue')
        },
        {
          path: 'update/:nannyId/reference/add',
          name: 'addNannyReference',
          component: () => import('../views/references/ReferencesAdd.vue')
        }
      ]
    }
  ]
}

export default routes
