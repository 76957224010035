export default {
  blog_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie Komentarze'
    },
    published: {
      icon: 'checkCircleSolid',
      text: 'Opublikowane'
    },
    work: {
      icon: 'brushSolid',
      text: 'Wersja Robocza'
    }
  },
  list: {
    postTitle: 'Tytuł posta',
    authorName: 'Autor posta'
  },
  toasts: {
    blogRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy wpisów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdBlog: {
      title: 'Blog został utworzony!',
      msg: 'Nastąpi przekierowanie do edycji wpisu.'
    },
    createdBlogError: {
      title: 'Coś poszło nie tak!',
      msg: 'Blog nie został utworzony.'
    },
    getBlogError: {
      title: 'Coś poszło nie tak!',
      msg: {
        categories: 'Wystąpił problem z pobraniem informacji o kategoriach.',
        authors: 'Wystąpił problem z pobraniem informacji o autorach.',
        covers: 'Wystąpił problem z pobraniem informacji o zdjęciach.',
        tags: 'Wystąpił problem z pobraniem informacji o tagach.'
      }
    },
    updatedBlog: {
      title: 'Wpis został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedBlogError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wpis nie został zmieniony.'
    },
    deletedBlogs: {
      title: 'Wpisy zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedBlogsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wpisy nie zostały usunięte.'
    },
    deletedBlog: {
      title: 'Wpis został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedBlogError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wpis nie został usunięty.'
    },
    changeIsActive: {
      title: 'Zmiany dla wpisów zostały zapisane!',
      msg: 'Dane zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status wpisów nie został zmieniony.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać informacji o autorze.',
      accept: 'Odśwież',
      decline: 'Anuluj'
    }
  }  
}
  