export default {
  payment_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie'
    },
    paid: {
      icon: 'checkCircleSolid',
      text: 'Zapłacone'
    },
    unpaid: {
      icon: 'exclamationCircleSolid',
      text: 'Nie zapłacone'
    }
  },
  list: {
    name: 'Imię i nazwisko',
    data: 'Data Transakcji'
  },
  toasts: {
    paymentsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy płatności.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z wyświetleniem szczegółów płatności.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    deletedPayments: {
      title: 'Płatności zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedPaymentsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Płatności nie zostały usunięte.'
    }
  }
}
