import { defineStore } from 'pinia'
interface NavbarData {
  text?: string
  to?: string
  toText?: number | string
}
export const useGlobalsStore = defineStore('globals', {
  state: () => ({
    navbarData: {} as NavbarData,
    currentItem: {},
    currentRoute: '',
    page: 1
  }),
  getters: {
    getNavbarData (state) {
      return state.navbarData
    },
    getCurrentItem (state) {
      return state.currentItem
    },
    getCurrentRoute (state) {
      return state.currentRoute
    },
    getPage (state) {
      return state.page
    }
  },
  actions: {
    async updateNavbarData (val: object) {
      this.navbarData = val
    },
    async updateCurrentItem (val: object) {
      this.currentItem = val
    },
    async updateCurrentRoute (val: string) {
      this.currentRoute = val
    },
    async updatePage (val: number) {
      this.page = val
    }
  }
})
