<template>
  <span class="inline-block relative flex-shrink-0">
    <img
      v-if="state.path.length > 0"
      draggable="false"
      :class="{
        'rounded-full': isRounded,
        'rounded-md': !isRounded,
        'h-8 w-8': size === 'xs' && !chipSize || size === 'lg' && chipSize,
        'h-10 w-10': size === 'sm' && !chipSize || size === 'xl' && chipSize,
        'h-12 w-12': size === 'md' && !chipSize,
        'h-16 w-16': size === 'lg' && !chipSize,
        'h-20 w-20': size === 'xl' && !chipSize,
        'h-5 w-5': size === 'xs' && chipSize,
        'h-6 w-6': size === 'sm' && chipSize,
        'h-7 w-7': size === 'md' && chipSize,
        [customStyle]: customStyle !== 'none'
      }"
      :style="customSize"
      :src="state.path"
      :alt="alt"
    >
    <span
      v-else
      class="inline-flex items-center justify-center"
      :class="{
        'rounded-full': isRounded,
        'rounded-md': !isRounded,
        [customStyle]: customStyle !== 'none',
        'bg-secondary-100': bg === 'light',
        'bg-primary-300': bg === 'primary',
        'bg-secondary-300': bg === 'secondary',
        'bg-success-300': bg === 'success',
        'bg-info-300': bg === 'info',
        'bg-warning-300': bg === 'warning',
        'bg-danger-300': bg === 'danger',
        'h-8 w-8': size === 'xs',
        'h-10 w-10': size === 'sm',
        'h-12 w-12': size === 'md',
        'h-16 w-16': size === 'lg',
        'h-20 w-20': size === 'xl'
      }"
      :style="customSize"
    >
      <span
        class="text-xs leading-none"
        :class="{
          'text-secondary-600': bg === 'light',
          'text-primary-100': bg === 'primary',
          'text-secondary-100': bg === 'secondary',
          'text-success-100': bg === 'success',
          'text-info-100': bg === 'info',
          'text-warning-100': bg === 'warning',
          'text-danger-100': bg === 'danger',
          'text-xs font-medium': size === 'xs' && customInitials.length < 1,
          'text-sm font-medium': size === 'sm' && customInitials.length < 1,
          'text-md font-normal': size === 'md' && customInitials.length < 1,
          'text-lg font-normal': size === 'lg' && customInitials.length < 1,
          'text-xl font-light': size === 'xl' && customInitials.length < 1,
          [customInitials]: customInitials.length > 1
        }"
      >{{ state.initials }}</span>
    </span>
    <span
      v-if="isOnline !== 'none' && !counter"
      class="absolute top-0 right-0 block rounded-full ring-2 ring-white"
      :class="{
        'transform -translate-y-1/2 translate-x-1/2': !isRounded,
        'h-2 w-2': size === 'xs',
        'h-2.5 w-2.5': size === 'sm',
        'h-3 w-3': size === 'md',
        'h-3.5 w-3.5': size === 'lg',
        'h-4 w-4': size === 'xl',
        'bg-secondary-300': isOnline === 'offline',
        'bg-success-500': isOnline === 'online',
        'bg-warning-500': isOnline === 'away',
        'bg-danger-500': isOnline === 'busy'
      }"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, reactive, watch, onMounted } from 'vue'

export default defineComponent({
  name: 'AvatarAtom',
  props: {
    alt: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (v: string) => ['custom', 'xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    },
    bg: {
      type: String,
      default: 'secondary',
      validator: (v: string) => ['light', 'primary', 'secondary', 'info', 'danger', 'warning', 'success'].includes(v)
    },
    isOnline: {
      type: String,
      required: false,
      default: 'none',
      validator: (v: string) => ['none', 'offline', 'online', 'busy', 'away'].includes(v)
    },
    isRounded: {
      type: Boolean,
      default: true
    },
    customStyle: {
      type: String,
      default: 'none'
    },
    customInitials: {
      type: String,
      default: ''
    },
    counter: {
      type: Boolean,
      default: false
    },
    chipSize: {
      type: Boolean,
      default: false
    },
    customSize: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // const avatarPath = computed(() => props.path)
    const state = reactive({
      initials: '',
      path: ''
    })

    const toInitials = (str: string) => str
      .split(' ')
      .map(c => c.charAt(0).toUpperCase())
      .join('')
      .concat(str.charAt(1).toUpperCase())
      .substring(0, 2)

    const returnAlt = () => props.alt

    if (!props.counter && !props.path) {
      state.initials = toInitials(props.alt) || ''
    }

    if (props.counter) {
      state.initials = returnAlt()
    }

    const getAvatarPath = () => {
      if (props.path.length > 0) {
        const string = '^avatars'
        const regexp = new RegExp(string)
        if (regexp.test(props.path)) {
          state.path = `${import.meta.env.VITE_AWS_BUCKET}${props.path}`
        } else {
          state.path = props.path
        }
      }
    }
    onMounted(() => {
      getAvatarPath()
    })
    watch(() => props.path, () => {
      state.initials = toInitials(props.alt) || ''
      getAvatarPath()
    })
    return {
      state
    }
  }
})
</script>

<style scoped>
  .rounded-full {
    border-radius: 50%;
  }
</style>
