import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/stats',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'stats',
          component: () => import('../views/stats/StatsIndex.vue')
        }
      ]
    }
  ]
}

export default routes
