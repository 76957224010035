export default {
  list: {
    name: 'Imię i nazwisko',
    email: 'Email'
  },
  toasts: {
    adminsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy zespołu ZN.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdAdmin: {
      title: 'Admin został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    createdAdminError: {
      title: 'Coś poszło nie tak!',
      msg: 'Admin nie został dodany.'
    },
    updatedAdmin: {
      title: 'Dane administratora zostały zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedAdminError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane administratora nie zostały zmienione.'
    },
    getAdminUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać danych administratora.'
    }
  }
}
  
