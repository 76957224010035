import { defineStore } from 'pinia'
import { LoginRes, User } from '@db/Auth/interfaces'
import apiAuth from '@db/Auth'
import { useLangsStore } from './langs'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    refresh: '',
    token: '',
    user: {} as User
  }),
  getters: {
    getUser (state) {
      return state.user
    },
    getUserFullname (state) {
      return `${state.user?.user_firstname} ${state.user?.user_lastname}`
    },
    getUserEmail (state) {
      return state.user?.email
    },
    getToken (state) {
      return state.token
    },
    isAuthenticated (state) {
      return state.token !== ''
    }
  },
  actions: {
    async login (data: LoginRes) {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      localStorage.removeItem('user')
      await this.tokens(data)
      await this.makeUser(data)
    },
    async tryAutoLogin () {
      const token = localStorage.getItem('token')
      const refresh = localStorage.getItem('refresh')
      if (token && refresh) {
        await this.login({token, refresh})
      }
    },
    async tokens (data: LoginRes) { 
      const { token, refresh } = data
      localStorage.setItem('token', token)
      localStorage.setItem('refresh', refresh)
      this.token = token
      this.refresh = refresh
    },
    async makeUser (data: LoginRes) {
      const { token, refresh } = data
      let user = JSON.parse(localStorage.getItem('user') || '{}')
      if (Object.getOwnPropertyNames(user).length === 0 || user === null) {
        const userRes = await apiAuth.getUser(token, refresh)
        localStorage.setItem('user', JSON.stringify(userRes.data))
        user = userRes.data
      }
      this.user = user
      const langs = useLangsStore()
      // langs.updateLang(user.user_lang)
      langs.updateLang('pl')
    },
    async saveProfil () {
      localStorage.removeItem('user')
      this.tryAutoLogin()
    },
    async logout () {
      this.refresh = ''
      this.token = ''
      this.user = {} as User
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      localStorage.removeItem('user')
    },
    async setUser (user: User) {
      localStorage.setItem('user', JSON.stringify(user))
      this.user = user
    },
    async refreshToken () {
      try {
        const token = localStorage.getItem('token')
        const refresh = localStorage.getItem('refresh')
        if (token && refresh) {
          const userRes = await apiAuth.getToken(token, refresh)
          await this.tokens(userRes.data as LoginRes)
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        throw new Error(error)
      }
    }
  }
})
