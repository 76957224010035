export default {
  list: {
    name: 'Imię i nazwisko'
  },
  toasts: {
    listRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy autorów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdWriter: {
      title: 'Autor został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    createdWriterError: {
      title: 'Coś poszło nie tak!',
      msg: 'Autor nie został dodany.'
    },
    updatedWriter: {
      title: 'Dane autora zostały zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedWriterError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane autora nie zostały zmienione.'
    },
    deletedWriters: {
      title: 'Autorzy zostali usunięci!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedWritersError: {
      title: 'Coś poszło nie tak!',
      msg: 'Autorzy nie zostali usunięci.'
    },
    deletedWriter: {
      title: 'Autor został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedWriterError: {
      title: 'Coś poszło nie tak!',
      msg: 'Autor nie został usunięty.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać informacji o autorze',
      accept: 'Odśwież',
      decline: 'Anuluj'
    },
    updatedWriterAvatar: {
      title: 'Zdjęcie autora zostało zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedWriterAvatarError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcie autora nie zostało zmienione.'
    },
    deletedWriterAvatar: {
      title: 'Zdjęcie autora zostało usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedWriterAvatarError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcie autora nie zostało usunięte.'
    }
  }
}
