import axios from 'axios'
import { useAuthStore } from '@stores/auth'
import router from '../router'
import type { ApiObj } from './Interfaces'

export class Axios {
  public async getHttp (obj: ApiObj) {
    const httpApi = axios.create({
      baseURL: obj.baseUrl
    })
    if (obj.internal) {
      const token = localStorage.getItem('token')
      if (token) {
        httpApi.defaults.headers.common['x-auth-token'] = token
      }
      const refresh = localStorage.getItem('refresh')
      if (refresh) {
        httpApi.defaults.headers.common['x-refresh-token'] = refresh
      }
      httpApi.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded, multipart/form-data'
      httpApi.defaults.withCredentials = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      httpApi.interceptors.response.use((res: any) => { return res }, async (error: any) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
          const useAuth = useAuthStore()
          await useAuth.refreshToken().then(() => {
            originalRequest.headers['x-auth-token'] = localStorage.getItem('token')
            originalRequest.headers['x-refresh-token'] = localStorage.getItem('refresh')
            originalRequest._retry = true
            return httpApi(originalRequest)
          }).catch(async () => {
            await useAuth.logout()
            router.push('/auth/logout')
          })
        }
        return Promise.reject(error)
      })
    } else {
      // httpApi.defaults.headers.common['x-app-key'] = import.meta.env.VITE_API_SCENARIOSKEY
      httpApi.defaults.headers.common['x-app-key'] = 'jakiskey'
    }
    
    return httpApi
  }
}
