<template>
  <button
    class="focus:outline-none focus:ring-2 focus:ring-primary-500 font-medium text-sm"
    :class="{
      'text-primary-600 hover:text-primary-500': type === 'accept',
      'text-secondary-700 hover:text-secondary-500': type === 'decline',
      'bg-white rounded-md focus:ring-offset-2': template === 'condensed' || template === 'action',
      'ml-3 flex-shrink-0': template === 'condensed' && type === 'accept',
      'ml-6': template === 'action' && type === 'decline',
      'w-full border border-transparent rounded-none flex items-center justify-center': template === 'reply' || template === 'splitreply',
      'rounded-r-lg p-4': template === 'reply' && type === 'accept',
      'rounded-tr-lg px-4 py-3 focus:z-10': template === 'splitreply' && type === 'accept',
      'rounded-br-lg px-4 py-3': template === 'splitreply' && type === 'decline',
    }"
  >
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToastBtnAtom',
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'accept',
      validator: (v: string) => ['accept', 'decline'].includes(v)
    },
    template: {
      type: String,
      default: 'reply',
      validator: (v: string) => ['condensed', 'reply', 'splitreply', 'action'].includes(v)
    }
  }
})
</script>
