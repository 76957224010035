export default {
  list: {
    name: 'Tag'
  },
  toasts: {
    listInfoRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy tagów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdTag: {
      title: 'Tag został utworzony!',
      msg: 'Dane zostały zapisane.'
    },
    createdTagError: {
      title: 'Coś poszło nie tak!',
      msg: 'Tag nie został utworzony.'
    },
    updatedTag: {
      title: 'Tag został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedTagError: {
      title: 'Coś poszło nie tak!',
      msg: 'Tag nie zostały zmieniony.'
    },
    deletedTags: {
      title: 'Tagi zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedTagsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Tagi nie zostały usunięte.'
    },
    deletedTag: {
      title: 'Tag został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedTagError: {
      title: 'Coś poszło nie tak!',
      msg: 'Tag nie został usunięty.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Informacje o tagu nie zostały pobrane.',
      accept: 'Spróbuj ponownie',
      decline: 'Anuluj'
    }
  }
}
