export default {
  list: {
    name: 'Nazwa Produktu'
  },
  toasts: {
    listRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy produktów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdProduct: {
      title: 'Produkt został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    createdProductError: {
      title: 'Coś poszło nie tak!',
      msg: 'Produkt nie został dodany.'
    },
    updatedProduct: {
      title: 'Produkt został zaktualizowany!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedProductError: {
      title: 'Coś poszło nie tak!',
      msg: 'Produkt nie został zaktualizowany.'
    },
    deletedProduct: {
      title: 'Produkt został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedProductError: {
      title: 'Coś poszło nie tak!',
      msg: 'Produkt nie został usunięty.'
    }
  }
}
