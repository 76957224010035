import { useGlobalsStore } from '@stores/globals'
import { RouteLocationNormalized } from 'vue-router'

export const redirect = async (to: RouteLocationNormalized) => {
  const useGlobals = useGlobalsStore()
  const route = useGlobals.getCurrentRoute
  const direct = to.fullPath.split('/')[1]
  if (route !== direct) {
    useGlobals.updateCurrentRoute(direct)
    useGlobals.updatePage(1)
  }
}
