import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/emails',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'emails',
          component: () => import('../views/emails/EmailsIndex.vue')
        },
        {
          path: ':userID/add',
          name: 'emailsAdd',
          component: () => import('../views/emails/EmailsAdd.vue')
        },
        {
          path: 'update/:emailID',
          name: 'updateEmails',
          component: () => import('../views/emails/EmailsUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
