import { RouterView } from 'vue-router' 
import LayoutDefault from '../layouts/LayoutBase.vue'

const routes = {
  path: '/hashtags',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'hashtags',
          component: () => import('../views/hashtags/HashtagsIndex.vue')
        },
        {
          path: ':userID/add',
          name: 'hashtagsAdd',
          component: () => import('../views/hashtags/HashtagsAdd.vue')
        },
        {
          path: 'update/:hashtagID/:hashtagValueID',
          name: 'hashtagValueUpdate',
          component: () => import('../views/hashtags/HashtagsUpdate.vue')
        }
      ]     
    }
  ]
}

export default routes
