<template>
  <svg
    aria-hidden="true"
    :class="`fill-current ${svgColor} w-${size} h-${size}`"
    :style="svgHex"
  >
    <use
      :href="symbolId"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'IconAtom',
  props: {
    prefix: {
      type: String,
      default: 'icon'
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    hex: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '6'
    }
  },
  setup (props) {

    const symbolId = computed(() => `#${props.prefix}-${props.name}`)
    const svgHex = computed(() => props.hex.length > 0 ? `color: ${props.hex}`: '')
    const svgColor = computed(() => props.color.length > 0 ? `text-${props.color}` : 'fill-current')
    return { symbolId, svgColor, svgHex }
  }
})
</script>
