export default {
  list: {
    title: 'Tytuł Zdjęcia'
  },
  toasts: {
    coversRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy zdjęć.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdCover: {
      title: 'Zdjęcie zostało dodane!',
      msg: 'Zmiany zostały zapisane.'
    },
    createdCoverError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcie nie zostało dodane.'
    },
    uploadCoverError: {
      title: 'Coś poszło nie tak!',
      msg: 'Plik przekracza dopuszczalny rozmiar (5mb)'
    },
    updatedCover: {
      title: 'Zdjęcie zostało zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedCoverError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcie nie zostało zmienione.'
    },
    deletedCovers: {
      title: 'Zdjęcia zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCoversError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcia nie zostały usunięte.'
    },
    deletedCover: {
      title: 'Zdjęcie zostało usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCoverError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zdjęcie nie zostało usunięte.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane zdjęcia nie zostały pobrane.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    }
  }
}
