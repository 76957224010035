<template>
  <div
    class="fixed w-full sidenav"
    :class="{
      'sidenav-mobile': sidenav.isMobile,
      'sidenav-mobile-open': sidenav.isMobile && sidenav.isOpen, 
      'sidenav-desktop': !sidenav.isMobile,
      'sidenav-desktop-open': !sidenav.isMobile && sidenav.isOpen
    }"
  >
    <div class="flex">
      <sidebar class="flex-none" />
      <div class="overflow-hidden flex-1">
        <router-view />
      </div>
    </div>
    <toasts-group />
  </div>
</template>

<script lang="ts">
import { defineComponent, onDeactivated, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import Sidebar from '@panel/Sidebar'
import { useSidenavStore } from '@stores/sidenav'
import ToastsGroup from '@molecules/ToastsGroup'

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    Sidebar,
    ToastsGroup 
  },
  setup () {
    const sidenav = useSidenavStore()
    const handleResize = () => {
      window.innerWidth > 1024 ? sidenav.setDesktop() : sidenav.setMobile()
    }

    onBeforeMount(() => {
      window.addEventListener('resize', handleResize)
      handleResize()
    })

    onDeactivated(() => {
      window.removeEventListener('resize', handleResize)
    })

    const route = useRoute()
    watch(route, () => {
      if (sidenav.isMobile) sidenav.close()
    })
    return {
      sidenav
    }
  }
})
</script>

<style lang="postcss" scoped>
.sidenav {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sidenav-mobile {
  transform: translate3d(-288px, 0, 0);
  width: calc(100vw + 288px);
}
.sidenav-mobile-open {
  transform: translate3d(0px, 0, 0);
}
.sidenav-desktop {
  width: 100%;
}
.sidenav-desktop-open {
  width: 100%;
  transform: translate3d(0px, 0, 0);
}
</style>
