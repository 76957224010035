/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import AbstractDB from '../AbstractDB'
import { LoginRes } from './interfaces'
class Auth extends AbstractDB {
  constructor () {
    super('auth')
  }

  public async getUser (token: string, refresh: string): Promise<AxiosResponse<unknown>> {
    (await this.api).defaults.headers.common['x-auth-token'] = token;
    (await this.api).defaults.headers.common['x-refresh-token'] = refresh
    return await this.get('')
  }
  
  public postLogin (user_name: string, user_password: string): Promise<AxiosResponse<LoginRes>> {
    return this.post('', { user_name, user_password })
  }

  public async getToken (token: string, refresh: string): Promise<AxiosResponse<unknown>> {
    (await this.api).defaults.headers.common['x-auth-token'] = token;
    (await this.api).defaults.headers.common['x-refresh-token'] = refresh
    return await this.get('token')
  }
  postRecover (obj: any) {
    return this.post('auth/recover', obj)
  }
  getRecover (recoverKey: any, userId: any) {
    return this.get(`auth/recover/${recoverKey}/${userId}`)
  }
  putPassword (recoverKey: any, userId: any, obj: any) {
    return this.put(`auth/password/${recoverKey}/${userId}`, obj)
  }
}
const apiAuth = new Auth()
export default apiAuth
