import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
import HomeIndex from '../views/home/HomeIndex.vue'
const routes = {
  path: '',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'home',
          component: HomeIndex
        },
        {
          path: 'settings',
          name: 'settings',
          component: () => import('../views/home/HomeSettings.vue')
        }
      ]
    }
  ]
}

export default routes
