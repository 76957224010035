import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/writers',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'writers',
          component: () => import('../views/writers/WritersIndex.vue')
        },
        {
          path: 'add',
          name: 'writersAdd',
          component: () => import('../views/writers/WritersAdd.vue')
        },
        {
          path: 'update/:writerID',
          name: 'writersUpdate',
          component: () => import('../views/writers/WritersUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
