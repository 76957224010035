import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/tags',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'tags',
          component: () => import('../views/tags/TagsIndex.vue')
        },
        {
          path: 'add',
          name: 'tagsAdd',
          component: () => import('../views/tags/TagsAdd.vue')
        },
        {
          path: 'update/:tagID',
          name: 'tagsUpdate',
          component: () => import('../views/tags/TagsUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
