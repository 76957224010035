import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import dayjs from 'dayjs'
import Maska from 'maska'
import i18n from './plugins/i18n'
import relativeTime from 'dayjs/plugin/relativeTime.js'
dayjs.extend(relativeTime)
import './tailwind.css'
import 'virtual:svg-icons-register'
import 'dayjs/locale/pl.js'

createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(router)
  .provide('dayjs', dayjs)
  .use(Maska)
  .mount('#app')
  