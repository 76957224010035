import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/blog',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'blog',
          component: () => import('../views/blog/BlogIndex.vue')
        },
        {
          path: 'add',
          name: 'blogAdd',
          component: () => import('../views/blog/BlogAdd.vue')
        },
        {
          path: 'update/:blogID',
          name: 'blogUpdate',
          component: () => import('../views/blog/BlogUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
