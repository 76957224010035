import { defineStore } from 'pinia'
import { Toast } from './types/toast'

export const useToastStore = defineStore('toast', {
  state: () => ({
    showTemplate: false,
    template: false,
    display: false,
    counter: 0,
    accept: false,
    decline: false,
    all: [] as Toast[]
  }),
  getters: {
    getAll: (state) => state.all,
    getCounter: (state) => state.counter,
    getAccept: (state) => state.accept,
    getDecline: (state) => state.decline
  },
  actions: {
    clear () {
      this.all = []
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    add (data: any) {
      this.counter++
      if (data.id) {
        const index = this.all.findIndex((item) => item.id === data.id)
        if (index > -1) {
          this.all[index].duration = data.duration
          return
        }
        this.all.push(data)
        return
      }

      const toast = Object.assign(data, { id: this.counter })
      this.all.push(toast)
    },
    remove ( id: number | string) {
      if (this.all.length > 0) this.all = this.all.filter(m => m.id !== id)
      if (this.all.length === 0) {
        this.counter = 0
      }
    },
    updateAccept (val: boolean) {
      this.accept = val
    },
    updateDecline (val: boolean) {
      this.decline = val
    },
    clearAction () {
      this.updateAccept(false)
      this.updateDecline(false)
    }
  }
})
