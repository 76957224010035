import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/users',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'users',
          component: () => import('../views/users/UsersIndex.vue')
        },
        {
          path: 'add',
          name: 'usersAdd',
          component: () => import('../views/users/UsersAdd.vue')
        },
        {
          path: 'update/:userID',
          name: 'usersUpdate',
          component: () => import('../views/users/UsersUpdate.vue')
        }
      ]
    }
  ]
}

export default routes
