<template>
  <div class="items-center justify-center flex h-[calc(100vh-45px)]">
    <div>
      <logo 
        slogan
        size="lg"
        is-vertical
      />
      <div class="p-10 max-w-3xl text-md lg:text-lg text-secondary-600 text-center">
        <p class="text-lg lg:text-2xl pb-10">
          Witaj <span class="text-primary-500">{{ name }}</span> w panelu administracyjnym Znanej Niani ver. {{ ver }}.
        </p> 
        <p>Możesz z tego miejsca edytować wszystkie dane w aplikacji.</p>
        <p>Bądź ostrożny - zmiany mogą być nieodwracalne.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent  } from 'vue'
import { useGlobalsStore } from '@stores/globals'
import { useAuthStore } from '@stores/auth'
import { version } from '../../../package.json'
import Logo from '@atoms/Logo'

export default defineComponent({
  components: {
    Logo
  },
  setup () {
    const useGlobals = useGlobalsStore()
    useGlobals.updateNavbarData({
      to: '',
      toText: '',
      text: 'Strona główna'
    })
    const auth = useAuthStore()
    const name = auth.getUser.user_name
    const ver = version

    return {
      name,
      ver
    }
  }
})
</script>
