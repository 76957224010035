import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/addresses',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'addresses',
          component: () => import('../views/addresses/AddressesIndex.vue')
        },
        {
          path: 'update/:addressID',
          name: 'updateAddress',
          component: () => import('../views/addresses/AddressesUpdate.vue')
        },
        {
          path: 'add/:userID',
          name: 'createAdress',
          component: () => import('../views/addresses/AddressesAdd.vue')
        },
        {
          path: 'add/main/:userID',
          name: 'createAdressMain',
          component: () => import('../views/addresses/AddressesAddMain.vue')
        }
      ]
    }
  ]
}

export default routes
