export default {
  address_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie'
    },
    main: {
      icon: 'homeSolid',
      text: 'Główne'
    },
    optional: {
      icon: 'buildingSolid',
      text: 'Dodatkowe'
    }
  },
  list: {
    city: 'Miasto',
    street: 'Ulica',
    type: 'Typ'
  },
  toasts: {
    update: {
      success: {
        title: 'Sukces!',
        msg: 'Adres został zmieniony'
      },
      fail: {
        title: 'Coś poszło nie tak',
        msg: `Wprowadzono nieprawidłowy adres.
          Proszę sprawdzić poprawność adresu.`,
        btn: 'Rozumiem'
      }
    },
    refreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy adresów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    adresssesUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem informacji o adresie.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    addressesChanged: {
      title: 'Adres został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    addressesChangedError: {
      title: 'Coś poszło nie tak!',
      msg: 'Adres nie został zmieniony.'
    },
    coordsChangedError: {
      title: 'Nie mogę pobrać współrzędnych geograficznych dla tego adresu!',
      msg: 'Adres nie został zmieniony.'
    },
    createdAdress: {
      title: 'Adres został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    createdAdressError: {
      title: 'Coś poszło nie tak!',
      msg: 'Adres nie został dodany.'
    }
  }
}
