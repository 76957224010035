import addresses from './addresses'
import announcements from './announcements'
import attributes from './attributes'
import auth from './auth'
import blog from './blog'
import categories from './categories'
import chats from './chats'
import comments from './comments'
import covers from './covers'
import deals from './deals'
import emails from './emails'
import excel from './excel'
import invites from './invites'
import kids from './kids'
import nannies from './nannies'
import pages from './pages'
import parents from './parents'
import payments from './payments'
import reviews from './reviews'
import stats from './stats'
import tags from './tags'
import admins from './admins'
import users from './users'
import writers from './writers'
import products from './products'
import references from './references'
import scenarios from './scenarios'

export default {
  addresses,
  announcements,
  attributes,
  auth,
  blog,
  categories,
  chats, 
  comments,
  covers,
  deals,
  emails,
  excel,
  invites,
  kids,
  nannies,
  pages,
  parents,
  payments,
  reviews,
  stats,
  tags,
  admins,
  users,
  writers,
  products,
  references,
  scenarios,
  title: 'Panel administracyjny',
  formFile: {
    select: 'Wybierz plik',
    drag: 'lub przeciągnij i upuść'
  }
}
