<template>
  <div class="grid grid-cols-3 gap-x-3 h-12 px-0 lg:px-6">
    <div class="flex items-center">
      <button
        class="inline-flex lg:hidden items-center justify-center p-2 ml-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-none"
        aria-expanded="false"
        @click="sidenav.change()"
      >
        <icon
          name="bars"
          class="fill-current"
        />
      </button>
      <router-link
        v-if="navbarData && navbarData.to"
        :to="navbarData.to"
        class="inline-flex lg:hidden items-center justify-center p-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-none"
        aria-expanded="false"
      >
        <icon
          name="arrow-left"
          class="fill-current"
        />
      </router-link>
      <router-link
        v-if="navbarData && navbarData.to"
        :to="navbarData.to"
        class="hidden lg:flex items-center font-normal text-sm text-secondary-600 hover:text-primary-600"
      >
        <icon
          name="arrow-left"
          class="mr-2"
        />
        <span
          v-if="navbarData.toText"
          class="truncate"
        >
          {{ navbarData.toText }}
        </span>
      </router-link>
    </div>
    <div class="flex justify-center items-center">
      <span
        v-if="navbarData && navbarData.text"
        class="font-bold text-sm lg:text-xl text-secondary-700 truncate"
      >
        {{ navbarData.text }}
      </span>
    </div>
    <div class="flex justify-end items-center">
      <router-link
        to="/settings"
        class="inline-flex lg:hidden items-center justify-center p-2 mr-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-none"
        aria-expanded="false"
      >
        <icon
          name="cogSolid"
          class="fill-current"
        />
      </router-link>
      <router-link
        to="/settings"
        class="hidden lg:flex items-center ml-2 font-normal text-sm text-secondary-600 hover:text-primary-600"
      >
        <span
          class="truncate mr-1"
        >
          Ustawienia
        </span>
        <icon
          size="4"
          name="cogSolid"
        />
      </router-link>
      <router-link
        to="/auth/logout"
        class="inline-flex lg:hidden items-center justify-center p-2 mr-2 rounded-md text-secondary-400 hover:text-secondary-500 hover:bg-secondary-100 focus:outline-none focus:ring-none"
        aria-expanded="false"
      >
        <icon
          name="signOutAltSolid"
          class="fill-current"
        />
      </router-link>
      <router-link
        to="/auth/logout"
        class="hidden lg:flex items-center ml-2 font-normal text-sm text-secondary-600 hover:text-primary-600"
      >
        <span
          class="truncate mr-1"
        >
          Wyloguj
        </span>
        <icon
          size="4"
          name="signOutAltSolid"
        />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSidenavStore } from '@stores/sidenav'
import { useGlobalsStore } from '@stores/globals'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'PanelNavbar',
  components: {
    Icon
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const title = computed(() => route.meta.title)
    const isComponent = computed(() => route.meta.isComponent)
    const sidenav = useSidenavStore()
    const useGlobal = useGlobalsStore()
    const navbarData = computed(() => useGlobal.getNavbarData)
    const returnTo = () => router.push({
      name: route.meta.module as string
    })
    return {
      sidenav,
      title,
      isComponent,
      returnTo,
      navbarData
    }
  }
})
</script>