export default function useOpacityQuark (duration = 200) {
  const opacityTransitionClasses = {
    enterFromClass: 'transition opacity-0',
    enterActiveClass: `transition duration-${duration} ease-linear`,
    enterToClass: 'transition opacity-100',
    leaveFromClass: 'transition opacity-100',
    leaveActiveClass: `transition duration-${duration} ease-linear`,
    leaveToClass: 'transition opacity-0'
  }
  return {
    opacityTransitionClasses
  }
}