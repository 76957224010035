export default {
  toasts: {
    scenariosRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy użytkowników.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    deletedScenario: {
      title: 'Scenariusz został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedScenarioError: {
      title: 'Coś poszło nie tak!',
      msg: 'Scenariusz nie został usunięty.'
    },
    createdScenario: {
      title: 'Masz nowy scenariusz!',
      msg: 'Scenariusz został dodany i możesz dodać do niego kroki.'
    },
    createdScenarioError: {
      title: 'Coś poszło nie tak!',
      msg: 'Scenariusz nie został dodany.'
    },
    saveScenario: {
      title: 'Zmiany zapisane',
      msg: 'Zmiany w scenariuszu zostały zapisane.'
    },
    saveScenarioError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zmiany w scenariuszu nie zostały zapisane.'
    },
    saveScenarioStep: {
      title: 'Zmiany zapisane',
      msg: 'Zmiany w etapie testu scenariusza zostały zapisane.'
    },
    saveScenarioStepError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zmiany w etapie testu scenariusza nie zostały zapisane.'
    },
    createdStep: {
      title: 'Etap został dodany!',
      msg: 'Etap testu scenariusza został dodany i możesz dodać do niego kroki.'
    },
    createdStepError: {
      title: 'Coś poszło nie tak!',
      msg: 'Etap testu scenariusza nie został dodany.'
    },
    deletedStep: {
      title: 'Etap został usunięty!',
      msg: 'Etap testu scenariusza został usunięty.'
    },
    deletedStepError: {
      title: 'Coś poszło nie tak!',
      msg: 'Etap testu scenariusza nie został usunięty.'
    }
  }
}
