import { useAuthStore } from '@stores/auth'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const tryAutoLogin = () => {
  const useAuth = useAuthStore()
  if (useAuth.token === '') {
    useAuth.tryAutoLogin()
  }
}

export const checkAuthenticated = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const useAuth = useAuthStore()
  if (to.path.includes('auth') && to.name !== 'authLogout') {
    if (useAuth.isAuthenticated) {
      next('/')
    } else {
      next()
    }
  } else {
    if (useAuth.isAuthenticated) {
      if (to.name === 'authLogout') {
        next()
      } else {
        next()
      }
    } else if (!useAuth.isAuthenticated) {
      next('/auth')
    }
  }
}
