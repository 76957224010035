import { defineStore } from 'pinia'

export const useSidenavStore = defineStore('sidenav', {
  state: () => ({
    open: true,
    mobile: false
  }),
  getters: {
    isOpen (state) {
      return state.open
    },
    isMobile (state) {
      return state.mobile
    }
  },
  actions: {
    change () {
      this.open = this.open !== true
    },
    close () {
      this.open = false
    },
    setMobile () {
      this.mobile = true
    },
    setDesktop () {
      this.mobile = false
    }
  }
})
