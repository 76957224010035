export default {
  invite_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    active: {
      icon: 'checkCircleSolid',
      text: 'Aktywne'
    },
    inactive: {
      icon: 'exclamationCircleSolid',
      text: 'Nieaktywne'
    }
  },
  list: {
    name: 'Imię i nazwisko'
  },
  toasts: {
    invitesRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy zaproszeń.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    invitesUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem informacji o zaproszeniu.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    updatedInvite: {
      title: 'Zaproszenie zostało zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedInviteError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zaproszenie nie zostało zmienione.'
    },
    deletedInvites: {
      title: 'Zaproszenia zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedInvitesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zaproszenia nie zostały usunięte.'
    },
    deletedInvite: {
      title: 'Zaproszenie zostało usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedInviteError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zaproszenie nie zostało usunięte.'
    },
    changeIsActive: {
      title: 'Zmiany dla zaproszeń zostały zapisane!',
      msg: 'Dane zostały zmienione.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status zaproszeń nie został zmieniony.'
    }
  }
}
