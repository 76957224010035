export default {
  review_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    positive: {
      icon: 'universalAccessSolid',
      text: 'pozytywne'
    },
    negative: {
      icon: 'coinsSolid',
      text: 'negatywne'
    },
    neutral: {
      icon: 'coinsSolid',
      text: 'neutralne'
    }
  },
  list: {
    name: 'Wystawiający ocenę'
  },
  toasts: {
    reviewsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy ocen.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    reviewsUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem informacji o ocenie.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    updatedReview: {
      title: 'Ocena została zmieniona!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedReviewError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ocena nie została zmieniona.'
    },
    deletedReview: {
      title: 'Ocena została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedReviewError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ocena nie została usunięta.'
    },
    deletedReviews: {
      title: 'Oceny zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedReviewsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Oceny nie zostały usunięte.'
    }
  }
}
