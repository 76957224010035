import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/kids',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'kids',
          component: () => import('../views/kids/KidsIndex.vue')
        },
        {
          path: 'update/:kidID',
          name: 'updateKid',
          component: () => import('../views/kids/KidsUpdate.vue')
        },
        {
          path: 'create/:parentID',
          name: 'createKid',
          component: () => import('../views/kids/KidsCreate.vue')
        },
        {
          path: 'update/:babyID/:attributeID',
          name: 'updateKidAttr',
          component: () => import('../views/attributes/ValuesChange.vue')
        }
      ]
    }
  ]
}

export default routes
