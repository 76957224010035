export default {
  chat_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    active: {
      icon: 'checkCircleSolid',
      text: 'Aktywne'
    },
    inactive: {
      icon: 'exclamationCircleSolid',
      text: 'Nieaktywne'
    }
  },
  list: {
    name: 'Imię i nazwisko'
    // type: 'Typ'
  },
  toasts: {
    chatsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy rozmów.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    deletedChats: {
      title: 'Rozmowy zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedChatsError: {
      title: 'Coś poszło nie tak!',
      msg: 'Rozmowy nie zostały usunięte.'
    },
    deletedChat: {
      title: 'Rozmowa została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedChatError: {
      title: 'Coś poszło nie tak!',
      msg: 'Rozmowa nie została usunięta.'
    },
    changeIsActive: {
      title: 'Zmiany dla rozmów zostały zapisane!',
      msg: 'Dane zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status rozmów nie został zmieniony.'
    },
    addedToastError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać informacji o rozmowach.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    }
  }
}
