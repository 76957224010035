export default {
  parent_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    free: {
      icon: 'universalAccessSolid',
      text: 'Free'
    },
    premium: {
      icon: 'coinsSolid',
      text: 'Premium'
    }
  },
  list: {
    name: 'Imię i nazwisko',
    type: 'Typ',
    account: 'Konto',
    district: 'Dzielnica',
    city: 'Miasto'
  },
  toasts: {
    parentsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy rodziców.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    removedUsers: {
      title: 'Użytkownicy zostali usunięci!',
      msg: 'Zmiany zostały zapisane.'
    },
    removedUsersError: {
      title: 'Coś poszło nie tak!',
      msg: 'Użytkownicy nie zostali usunięci.'
    },
    changeIsActive: {
      title: 'Zmiany dla użytkowników zostały zapisane.',
      msg: 'Dane zostały zmienione.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status użytkowników nie został zmieniony.'
    },
    addedToastError: {
      title: 'Coś poszło nie tak!',
      msg: 'Nie udało się pobrać informacji o rodzicu.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    deletedKid: {
      title: 'Profil dziecka został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedKidError: {
      title: 'Coś poszło nie tak!',
      msg: 'Profil dziecka nie został usunięty.'
    },
    deletedAnnouncement: {
      title: 'Ogłoszenie zostało usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAnnouncementError: {
      title: 'Coś poszło nie tak!',
      msg: 'Ogłoszenie nie zostało usunięte.'
    }
  }  
}
