export default {
  attr_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie'
    },
    active: {
      icon: 'checkCircleSolid',
      text: 'Aktywne'
    },
    inactive: {
      icon: 'exclamationCircleSolid',
      text: 'Nieaktywne'
    }
  },
  toasts: {
    listInfoRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy cech.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    createdAttribute: {
      title: 'Cecha została dodana!',
      msg: 'Dane zostały zapisane.'
    },
    createdAttributeError: {
      title: 'Coś poszło nie tak!',
      msg: 'Cecha nie została dodana.'
    },
    updatedAttribute: {
      title: 'Dane cechy zostały zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedAttributeError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane cechy nie zostały zmienione.'
    },
    updatedAttributesValue: {
      title: 'Wartość cechy została zmieniona!',
      msg: 'Zmiany zostały zapisane.'
    },
    updatedAttributesValueError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wartość cechy nie została zmieniona.'
    },
    createdAttributesValue: {
      title: 'Wartość cechy została dodana!',
      msg: 'Dane zostały zapisane.'
    },
    createdAttributesValueError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wartość cechy nie została dodana.'
    },
    deletedAttributesValue: {
      title: 'Wartość cechy została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAttributesValueError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wartość cechy nie została usunięta.'
    },
    deletedAttributes: {
      title: 'Cechy zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAttributesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Cechy nie zostały usunięte.'
    },
    deletedAttribute: {
      title: 'Cecha została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedAttributeError: {
      title: 'Coś poszło nie tak!',
      msg: 'Cecha nie została usunięta.'
    },
    changeIsActive: {
      title: 'Zmiany dla cech zostały zapisane!',
      msg: 'Dane zostały zmienione.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zmiany dla cech nie zostały zapisane.'
    },
    addedToastError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane cechy nie zostały pobrane.',
      accept: 'Odśwież listę cech',
      decline: 'Anuluj'
    },
    getLoadError: {
      title: 'Coś poszło nie tak!',
      msg: 'Błąd podczas wczytywania listy cech.'
    },
    readAttrError: {
      title: 'Coś poszło nie tak!',
      msg: 'Błąd podczas wczytywania danych cechy.'
    },
    getDataError: {
      title: 'Coś poszło nie tak!',
      msg: 'Dane wartości nie zostały pobrane.'
    }
  },
  list: {
    tagname: 'Nazwa cechy',
    field_type: 'Typ formularza',
    is_active: 'Typ cechy'
  }
}
 