export default {
  category_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszystkie'
    },
    active: {
      icon: 'universalAccessSolid',
      text: 'Aktywne'
    },
    inactive: {
      icon: 'coinsSolid',
      text: 'Nieaktywne'
    }
  },
  list: {
    category: 'Nazwa kategorii',
    type: 'Typ kategorii'
  },
  toasts: {
    categoriesRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy kategorii.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    categoriesUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem informacji o kategorii.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    categoriesChanged: {
      title: 'Kategoria została zaktualizowana!',
      msg: 'Zmiany zostały zapisane.'
    },
    categoriesChangedError: {
      title: 'Coś poszło nie tak!',
      msg: 'Kategoria nie została zaktualizowana.'
    },
    createdCategory: {
      title: 'Kategoria została dodana!',
      msg: 'Dane zostały zapisane.'
    },
    createdCategoryError: {
      title: 'Coś poszło nie tak!',
      msg: 'Kategoria nie została dodana.'
    },
    deletedCategories: {
      title: 'Kategorie zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCategoriesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Kategorie nie zostały usunięte.'
    },
    deletedCategory: {
      title: 'Kategoria została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedCategoryError: {
      title: 'Coś poszło nie tak!',
      msg: 'Kategoria nie została usunięta.'
    },
    changeIsActive: {
      title: 'Zmiany dla kategorii zostały zapisane!',
      msg: 'Dane zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status kategorii nie został zmieniony.'
    }
  }
}
