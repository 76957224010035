import { RouterView } from 'vue-router'
import LayoutDefault from '../layouts/LayoutBase.vue'
const routes = {
  path: '/scenarios',
  component: LayoutDefault,
  children: [
    {
      path: '',
      components: {
        default: RouterView
      },
      children: [
        {
          path: '',
          name: 'scenariosIndex',
          component: () => import('../views/scenarios/ScenariosIndex.vue')
        },
        {
          path: ':scenarioId/steps',
          name: 'scenariosSteps',
          component: () => import('../views/scenarios/ScenariosSteps.vue')
        },
        {
          path: ':scenarioId/update',
          name: 'scenariosUpdate',
          component: () => import('../views/scenarios/ScenariosUpdate.vue')
        },
        {
          path: 'create',
          name: 'scenariosCreate',
          component: () => import('../views/scenarios/ScenariosCreate.vue')
        },
        {
          path: ':scenarioId/create',
          name: 'scenariosCreateStep',
          component: () => import('../views/scenarios/ScenariosCreateStep.vue')
        },
        {
          path: ':scenarioId/update/:stepId',
          name: 'scenariosUpdateStep',
          component: () => import('../views/scenarios/ScenariosUpdateStep.vue')
        }
      ]
    }
  ]
}

export default routes
