export default {
  nanny_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    free: {
      icon: 'universalAccessSolid',
      text: 'Free'
    },
    premium: {
      icon: 'coinsSolid',
      text: 'Premium'
    }
  },
  list: {
    name: 'Imię i nazwisko',
    type: 'Typ',
    account: 'Konto',
    district: 'Dzielnica',
    city: 'Miasto'
  },
  toasts: {
    nanniesRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy niań.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    removedUsers: {
      title: 'Użytkownicy zostali usunięci!',
      msg: 'Zmiany zostały zapisane.'
    },
    removedUsersError: {
      title: 'Coś poszło nie tak!',
      msg: 'Użytkownicy nie zostali usunięci.'
    },
    changeIsActive: {
      title: 'Status użytkowników został zmieniony!',
      msg: 'Zmiany zostały zapisane.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status użytkowników nie został zmieniony.'
    }
  }
}
