<template>
  <div class="h-screen w-[288px] overflow-y-auto bg-secondary-600 flex flex-col px-0 py-2 dark:bg-gray-700 aside-menu">
    <router-link
      to="/"
      class="flex items-center flex-shrink-0 mb-3 px-4"
    >
      <img
        class="block w-auto h-9 mr-2 cursor-pointer"
        src="@assets/logo.png"
        alt="Logo"
      >
      <div>
        <span
          class="block text-2xl font-light text-gray-100"
        >
          znana <span class="font-bold">niania</span>
        </span>
        <span
          class="block text-xs font-light text-primary-500"
        >
          Panel Administracyjny ver. {{ ver }}
        </span>
      </div>
    </router-link>
    <div 
      v-for="(section, ids) in sections"
      :key="ids"
      class="mt-2"
    >
      <div class="flex justify-between items-center mb-2 mx-4">
        <div class="font-light text-secondary-300 select-none whitespace-nowrap truncate text-xs uppercase">
          {{ section.title }}
        </div>
      </div>
      <ul class="mb-4 text-sm">
        <li
          v-for="(item, idx) in section.arr"
          :key="idx"
        >
          <router-link
            v-slot="{ href, navigate, isActive }"
            custom
            :to="item.link"
          >
            <a
              class="h-10 flex items-center px-4 cursor-pointer font-medium whitespace-nowrap truncate text-base md:text-sm"
              :class="[ isActive ? 'text-primary-500 hover:text-primary-400 bg-secondary-500':'text-gray-200 hover:bg-secondary-500 hover:text-gray-100 opacity-80 hover:opacity-100']"
              :href="href"
              @click="navigate"
            >
              <icon
                size="5"
                :name="item.icon"
                class="mr-2 fill-current flex-shrink-0 h-5 w-5 transform ease-in-out duration-90 text-secondary-200 group-hover:text-secondary-300"
              />
              <span>{{ item.name }}</span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@atoms/Icon'
import { version } from '../../../../package.json'

export default defineComponent({
  name: 'PanelSidebar',
  components: {
    Icon
  },
  setup () {
    console.log('Sidebar log')
    const ver = version
    const tests = [
      { name: 'Scenariusze', link: { name: 'scenariosIndex' }, icon: 'universalAccessSolid' }
    ]

    const links = [
      { name: 'Użytkownicy', link: { name: 'users' }, icon: 'usersSolid' },
      { name: 'Rodzice', link: { name: 'parents' }, icon: 'userCircle' },
      { name: 'Nianie', link: { name: 'nannies' }, icon: 'babyCarriageSolid' },
      { name: 'Dzieci', link: { name: 'kids' }, icon: 'childSolid' },
      { name: 'Adresy', link: { name: 'addresses' }, icon: 'mapMarkerSolid' },
      { name: 'Emaile', link: { name: 'emails' }, icon: 'envelope' },
      { name: 'Referencje', link: {name: 'references'}, icon: 'fileAltSolid'}
    ]

    const relations = [
      { name: 'Ogłoszenia', link: { name: 'announcements' }, icon: 'mapMarkedSolid' },
      { name: 'Cechy', link: { name: 'attributes' }, icon: 'tagsSolid' },
      { name: 'Oceny', link: { name: 'reviews' }, icon: 'starSolid' },
      { name: 'Zaproszenia', link: { name: 'invites' }, icon: 'userPlusSolid' },
      { name: 'Rozmowy', link: { name: 'chats' }, icon: 'commentDots' },
      { name: 'Współprace', link: { name: 'deals' }, icon: 'handshake' },
      { name: 'Hashtagi', link: {name: 'hashtags'}, icon: 'hashtagSolid'}
    ]


    const payments = [
      { name: 'Rozliczenia', link: { name: 'payments' }, icon: 'fileInvoiceDollarSolid' },
      { name: 'Produkty', link: { name: 'products' }, icon: 'shoppingCartSolid' }
    ]

    const blog = [
      { name: 'Blog', link: { name: 'blog' }, icon: 'fileAltSolid' },
      { name: 'Autorzy', link: { name: 'writers' }, icon: 'userEditSolid' },
      { name: 'Kategorie', link: { name: 'categories' }, icon: 'listSolid' },
      { name: 'Tagi', link: { name: 'tags' }, icon: 'hashtagSolid' },
      { name: 'Covery wpisów', link: { name: 'covers' }, icon: 'image' },
      { name: 'Komentarze', link: { name: 'comments' }, icon: 'comments' }
    ]

    const others = [
      { name: 'Strony informacyjne', link: { name: 'pages' }, icon: 'infoCircleSolid' },
      { name: 'Statystyki', link: { name: 'stats' }, icon: 'chartBarSolid' },
      { name: 'Eksporty Excel', link: { name: 'excel' }, icon: 'fileExcel' },
      { name: 'Zespół ZN', link: { name: 'admins' }, icon: 'usersCogSolid' }
    ]


    const sections = [
      { title: 'Scenariusze testowe', arr: tests},
      { title: 'Zarządzenie kontami', arr: links},
      { title: 'Aktywność i relacje', arr: relations},
      { title: 'Płatności', arr: payments},
      { title: 'Blog', arr: blog},
      { title: 'Inne', arr: others}
    ]
    return {
      ver,
      sections
    }
  }
})
</script>

<style scoped>
.aside-menu::-webkit-scrollbar {
    position: absolute;
    width: 6px;
}
.aside-menu::-webkit-scrollbar-thumb {
    background-color: rgb(72, 126, 150, 0.4);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
</style>