import { defineStore } from 'pinia'
export const useLangsStore = defineStore('langs', {
  state: () => ({
    lang: '',
    langs: ['pl', 'en']
  }),
  getters: {
    getLang (state) {
      return state.lang
    },
    getLangs (state) {
      return state.langs
    }
  },
  actions: {
    updateLang (value: string) {
      localStorage.setItem('language', value)
      this.lang = value
    },
    trySetLang (value: string) {
      const lang = localStorage.getItem('language')
      if (lang) {
        if (lang === value) {
          this.updateLang(value)
        } else {
          this.updateLang(lang)
        }
      } else {
        this.updateLang(value)
      }
    }
  }
})
