export default {
  list: {
    title: 'Tytuł strony',
    fullname: 'Nazwisko autora'
  },
  toasts: {
    listRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy stron.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    pagesChanged: {
      title: 'Strona została zmieniona!',
      msg: 'Zmiany zostały zapisane.'
    },
    pagesChangedError: {
      title: 'Coś poszło nie tak!',
      msg: 'Strona nie została zmieniona.'
    },
    pagesUpdateError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem informacji o stronach.',
      accept: 'Odśwież stronę',
      decline: 'Anuluj'
    },
    createdPage: {
      title: 'Strona została dodana!',
      msg: 'Nastąpi przekierowanie do edycji strony.'
    },
    createdPageError: {
      title: 'Coś poszło nie tak!',
      msg: 'Strona nie została dodana.'
    },
    deletedPages: {
      title: 'Strony zostały usunięte!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedPagesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Strony nie zostały usunięte.'
    },
    deletedPage: {
      title: 'Strona została usunięta!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedPageError: {
      title: 'Coś poszło nie tak!',
      msg: 'Strona nie została usunięta.'
    }
  }  
}
