export default {
  user_type: {
    all: {
      icon: 'listSolid',
      text: 'Wszyscy'
    },
    free: {
      icon: 'universalAccessSolid',
      text: 'Free'
    },
    premium: {
      icon: 'coinsSolid',
      text: 'Premium'
    }
  },
  list: {
    name: 'Imię i nazwisko',
    type: 'Typ',
    account: 'Konto',
    profile: 'Stan Profilu',
    active: 'Stan Konta'
  },
  toasts: {
    usersRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobraniem listy użytkowników.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    emailsRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem listy adresów email.',
      accept: 'Odśwież listę',
      decline: 'Anuluj'
    },
    avatarRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem avatara.',
      accept: 'Odśwież avatara',
      decline: 'Anuluj'
    },
    regRefreshError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem informacji rejestracyjnych.',
      accept: 'Odśwież informacje z rejestracji',
      decline: 'Anuluj'
    },
    addressesError: {
      title: 'Coś poszło nie tak!',
      msg: 'Wystąpił problem z pobieraniem informacji o adresach.',
      accept: 'Odśwież informacje o adresach',
      decline: 'Anuluj'
    },
    addedUser: {
      title: 'Użytkownik został dodany!',
      msg: 'Dane zostały zapisane.'
    },
    addedUserError: {
      title: 'Coś poszło nie tak!',
      msg: 'Użytkownik nie został dodany.'
    },
    removedUsers: {
      title: 'Użytkownicy zostali usunięci!',
      msg: 'Zmiany zostały zapisane.'
    },
    removedUsersError: {
      title: 'Coś poszło nie tak!',
      msg: 'Użytkownicy nie zostali usunięci.'
    },
    deletedUser: {
      title: 'Użytkownik został usunięty!',
      msg: 'Zmiany zostały zapisane.'
    },
    deletedUserError: {
      title: 'Coś poszło nie tak!',
      msg: 'Użytkownik nie został usunięty.'
    },
    changeIsActive: {
      title: 'Zmiany dla użytkowników zostały zapisane!',
      msg: 'Dane zostały zmienione.'
    },
    changeIsActiveError: {
      title: 'Coś poszło nie tak!',
      msg: 'Status użytkowników nie został zmieniony.'
    },
    addNewPassword: {
      title: 'Hasło zostało zmienione!',
      msg: 'Zmiany zostały zapisane.'
    },
    addNewPasswordError: {
      title: 'Coś poszło nie tak!',
      msg: 'Zmiany nie zostały zapisane.'
    }
  }
}
